@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add custom animation */
@layer utilities {
  @keyframes pop {
    0%, 100% {
      transform: scale(1);
    }
    5% {
      transform: scale(1.05);
    }
  }
  .animate-pop {
    animation: pop 1s ease-in-out infinite;
  }
}
